var Sensations = {
  tension: {
    title: 'Stress and tension',
    sensations: [
      `Headache`,
      `Stiff neck`,
      `Exhausted`,
      `Hungry`,
      'Nauseas',
    ],
    feelings: ['tension', 'resistance', 'apathy'],
  },
  fear: {
    title: 'Fear and anxiety',
    sensations: [
      `Tight chest`,
      `Lump in throat`,
      `Restless`,
    ],
    feelings: ['fear', 'tension', 'resistance'],
  },
  shame: {
    title: 'Shame and inadequacy',
    sensations: [
      `Burning face`,
    ],
    feelings: ['shame', 'guilt', 'inadequacy', 'fear'],
  },
  sadness: {
    title: 'Sadness and grief',
    sensations: [
      `Heavy heart`,
    ],
    feelings: ['sadness', 'guilt', 'apathy'],
  },
  anger: {
    title: 'Anger',
    sensations: [
      `Clenched jaw`,
      `Griding teeth`,
      `Tense forehead`,
    ],
    feelings: ['anger', 'tension', 'resistance'],
  },
};

export default Sensations;

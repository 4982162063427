<template>
  <f7-page :page-content="false" name="guide">
    <f7-navbar theme-dark style="height: 40px;">
      <f7-nav-left back-link="Back"></f7-nav-left>
      <f7-nav-title subtitle="Viewing guest session"></f7-nav-title>
    </f7-navbar>
    <f7-page-content style="padding-top: 40px;">
      <div id="viewer" ref="viewer">
      </div>
    </f7-page-content>
  </f7-page>
</template>

<style>
  #viewer {
    width: 100%;
    height: 100%;
  }
  .replayer-wrapper {
    width: 100%;
    height: 100%;
    background: url('../static/img/loader.gif');
    background-size: 32px;
    background-position: center;
    background-repeat: no-repeat;
  }
  .replayer-mouse-tail {
    display: none;
  }
  iframe {
    border: none;
  }
</style>

<script>
  import Record from '../js/record.js';
  import * as rrweb from 'rrweb';

  export default {
    mounted() {
      const ws = Record.connect(this.$f7route.params.group);

      rrweb.Replayer.prototype.handleResize = function() {
        this.iframe.style.display = 'inherit';
        this.iframe.setAttribute('width', '100%');
        this.iframe.setAttribute('height', '100%');
      };
      var replayer = new rrweb.Replayer([], {
        liveMode: true,
        root: this.$refs.viewer,
        mouseTail: false,
      });

      ws.addEventListener('open', function open() {
        Record.refresh(ws);
        replayer.startLive();
      });

      ws.addEventListener('message', function incoming(data) {
        var event = JSON.parse(data.data);
        replayer.addEvent(event);
      });
    },
}
</script>
<template>
  <f7-page name="summary">
    <wuju-navbar title="Wuju" subtitle="be gentle to yourself" :menu="true" :back="false"></wuju-navbar>
    <f7-block>
      <wuju-quote :quotes="quotes"></wuju-quote>
    </f7-block>
    <f7-block>
      <wuju-contact v-if="lesson.text.summary.trim()"></wuju-contact>
      <p v-if="lesson.text.summary.trim()" class="content" v-html="linebreaks(lesson.text.summary)"></p>
      <div v-if="answer()">
        <f7-list-item title="Remember this?" group-title></f7-list-item>
        <p class="rows answer">{{answer()}}</p>
      </div>
      <!-- <f7-list-item title="How do you feel now?" group-title></f7-list-item>
      <textarea ref="reflection" class="rows resizable tall answer" :value="reflection" @change="reflection = $event.target.value"></textarea> -->
      <!-- <f7-list-item class="center" title="Share Wuju with friends and family" group-title></f7-list-item> -->
      <p class="center" style="background: #ddd; padding: 20px">Share Wuju with friends and family</p>
      <br>
      <wuju-share title="Wuju stress relief" message="Lack of control and lack of clarity in the current situation can lead to anxiety and stress. This app can help."></wuju-share>
    </f7-block>
    <f7-toolbar position="bottom">
      <f7-link></f7-link>
      <f7-button @click="done()">Done</f7-button>
      <f7-link></f7-link>
    </f7-toolbar>
  </f7-page>
</template>

<style scoped>
  .answer {
    min-height: 7em;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
    margin-bottom: 30px;
  }

  .content {
    min-height: 100px;
    margin-bottom: 30px;
  }
</style>

<script>
import Lessons from '../js/lessons.js';
import Utils from '../js/utils.js';
import Quotes from '../js/quotes.js';
import Settings from '../js/settings.js';
import Email from '../js/email.js';
import Narration from '../js/narration.js';

export default {
  data: function() {
    return {
        lessonKey: '',
        lesson: {},
        reflection: '',
        quotes: Quotes,
    }
  },
  methods: {
    feedback() {
        Email.openContactDialog(this.$f7, 'How was it?', `I'd really like to know how this was for you. Was there anything you stumbled on? Do you need any help? I reply to every message I get.`);
    },
    answer() {
      if (this.lesson.question && this.lesson.key == this.$store.lesson.key) {
        return this.$store.lesson.answer;
      }
    },
    shareMessage() {
      if (this.answer()) {
        return `${this.answer()}\n\nAfter a few minutes with Wuju:\n\n${this.reflection}`;
      } else {
        return `${this.reflection}`;
      }
    },
    done() {
      Settings.insights.add({
        text: this.shareMessage(),
      });
      if (Settings.shown.get('survey')) {
        this.$f7router.navigate(`/home`);
      } else {
        this.$f7router.navigate(`/survey`);
      }
    },
    ...Utils,
  },
  beforeMount: function() {
    this.lesson = Lessons[this.$f7route.params.lesson];
  },
  mounted() {
    Settings.lessons.set(this.$f7route.params.lesson);
    Narration.interrupt().delay(500).play(this.lesson.narration.summary);
  }
}
</script>
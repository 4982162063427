<template>
  <f7-link class="thumbnail" @click="contact()">
    <img src="static/img/eli.jpg">
    <f7-icon class="contact" f7="chat_bubble_text_fill" size="16" color="orange"></f7-icon>
  </f7-link>
</template>

<style scoped>
  .thumbnail {
    float: right;
    position: relative;
    top: 10px;
    margin: 0 0 10px 10px;
    position: relative;
  }
  .thumbnail img {
    width: 70px;
    border-radius: 10px;
  }
  .thumbnail .contact {
    position: absolute;
    bottom: 5px;
    right: 7px;
  }
</style>

<script>
  import Email from '../js/email.js';

  export default {
    methods: {
      contact() {
        Email.openContactDialog(this.$f7);
      }
    },
  }
</script>
<template>
  <f7-page name="graph">
    <div id="graph"></div>
  </f7-page>
</template>

<style>
  #graph svg {
    width: 100%;
  }
</style>

<script>
  import {graphviz} from "d3-graphviz";
  import Flows from '../js/flows.js';
  import Feelings from '../js/feelings.js';
  import Utils from '../js/utils.js';
  export default {
    mounted() {
      this.$$('body').removeClass('app');

      const wrap = (s) => s.replace(
          /(?![^\n]{1,16}$)([^\n]{1,16})\s/g, '$1\n'
      );
      var feeling = Feelings[this.$f7route.query.feeling || 'anger'];
      var flow = Flows[this.$f7route.params.flow];
      var edges = [];
      var nodes = [];

      var deadend = 1;
      Object.keys(flow).forEach((key) => {
        var state = flow[key];
        var text = Utils.template(state.text, {feeling: feeling}) || key;
        Object.keys(state.buttons).forEach((button) => {
          var weight = 1;
          var action = state.buttons[button];
          if (action === '') {
            action = `deadend_${deadend}`;
            deadend++;
          }
          edges.push(`${key} -> ${action} [label="${button}"] [weight=${weight}]`);
        });
        nodes.push(`${key} [label="${wrap(text)}"] [shape=box];`)
      });

      graphviz('#graph', { scale: 0.8 } ).renderDot(`
        digraph {
          ${nodes.join('\n')}
          ${edges.join('\n')}
        }
        `).onerror((e) => {
          console.error(e)
      });
    },
    beforeDestroy() {
      this.$$('body').addClass('app');
    }
}
</script>
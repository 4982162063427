import * as rrweb from 'rrweb';
import { customAlphabet } from 'nanoid';

var Record = {
  ws: null,
  _group: null,

  connect(group) {
    var ws = new WebSocket('wss://dev.wuju.app:3000?group=' + group);

    return ws;
  },

  refresh(ws) {
    // ask all other connected clients to refresh
    ws.send(JSON.stringify({type: 'refresh'}));
  },

  record(group) {
    if (this.ws) {
      this.ws.close();
    }

    this.ws = this.connect(group);
    var self = this;

    // record screen and send events over WebSocket
    this.ws.addEventListener('open', () => {
      self._stop = rrweb.record({
        emit(event) {
          if (self.ws.readyState !== self.ws.OPEN) {
            self.stop();
            return;
          }
          self.ws.send(JSON.stringify(event));
        },
      });
    });

    // restart recording upon refresh message
    this.ws.addEventListener('message', (event) => {
      var data = JSON.parse(event.data);
      if (data.type === 'refresh') {
        self.stop();
        self.record(group);
      }
    });
  },

  stop() {
    this._stop();
    this.ws.close();
  },

  groupId() {
    const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 6);
    if (! this._group) {
      this._group = nanoid();
    }
    return this._group;
  },

  _stop: () => {},
}

export default Record;

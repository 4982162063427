import Email from '../js/email.js';
import Utils from '../js/utils.js';
import Settings from '../js/settings.js';

var SurveyFlow = {
  start: {
    text: `Would you take 2 minutes and fill out a quick survey?<br>It will help me improve the app for you and others.`,
    buttons: { 'Sure': 'started', 'Not now': '__exit__' },
  },
  started: {
    text: `(1/4) Was the process helpful to you? How did it help?`,
    control: {
      type: 'textarea'
    },
    buttons: { 'Next' : 'unclear' }
  },
  unclear: {
    text: `(2/4) Was there anything you didn't understand?`,
    control: {
      type: 'textarea'
    },
    buttons: { 'Next' : 'bugs' }
  },
  bugs: {
    text: `(3/4) Did you encounter any bugs or problems?`,
    control: {
      type: 'textarea'
    },
    buttons: { 'Next' : 'next' }
  },
  next: {
    text: `(4/4) What's the next thing I should work on?`,
    control: {
      type: 'pills',
      items: ['more thoughts', 'more guided sessions', 'a real mobile app', 'hindi translation'],
      multiselect: true,
      block: true,
    },
    buttons: { 'Next' : 'thanks' },
    after: (card, data, action, answers) => {
      var message = Object.keys(answers)
        .filter((key) => answers[key])
        .map((key) => SurveyFlow[key].text + '\n' + answers[key])
        .join('\n');
      Email.send('wuju_template_message', {
        email: answers.email || 'survey@wuju.app',
        subject: 'Wuju survey',
        message: Utils.linebreaks(message + '\n' + JSON.stringify(answers)),
      });
      Settings.shown.set('survey');
    }
  },
  thanks: {
    text: `Thanks for taking the time!`,
    buttons: { 'Done': '__exit__' },
  },
}

export default SurveyFlow;
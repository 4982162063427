<template>
  <f7-page name="home">
    <wuju-navbar :menu="true" :back="true" title="Insights"></wuju-navbar>
    <div v-if="sortedInsights.length">
      <f7-card v-for="(insight, index) in sortedInsights" :key="index">
        <f7-card-content>
          {{insight.text}}
        </f7-card-content>
        <f7-card-footer>
          <p class="flex">{{moment(insight.date).fromNow()}}</p>
          <span>
            <!-- <f7-button class="inline" :href="twitterIntent(insight)" icon-ios="f7:trash" icon-md="material:delete" icon-size="20"></f7-button> -->
            <!-- <f7-button class="inline" :href="twitterIntent(insight)" icon-ios="f7:square_arrow_up" icon-md="material:share" icon-size="20"></f7-button> -->
            <wuju-share :message="insight.text" :labels="false"></wuju-share>
          </span>
        </f7-card-footer>
      </f7-card>
    </div>
    <f7-block v-if="! sortedInsights.length">
      <p class="center">There are no insights here yet. Anything you write down as part of your process will appear here.</p>
    </f7-block>
  </f7-page>
</template>

<script>
import Utils from '../js/utils.js';
import Music from '../js/music.js';
import Settings from '../js/settings.js';
import moment from 'moment';

export default {
  // todo: add explanation about insights when there are no insights
  // todo: add help button inside the insight box

  data: function() {
    return {
      insights: Settings.insights.get(),
    }
  },
  methods: {
    twitterIntent(insight) {
      const hostname = window.location.hostname == 'localhost' ? 'wuju.app' : window.location.hostname;
      const message = `${insight.text.trim()}\n\nvia ${hostname}`;
      return `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`;
    },
    moment: moment,
  },
  computed: {
    sortedInsights() {
      return this.insights.sort((a, b) => moment(b.date) - moment(a.date));
    }
  }
}

</script>

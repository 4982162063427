import DefaultFlow from './default-flow.js';

export default Object.assign({}, DefaultFlow, {
  start: {
    text: `What is your current feeling?`,
    control: {
      type: 'text',
      placeholder: 'e.g. sadness, tension, anxiety',
    },
    buttons: { 'OK': 'start2' },
    after: (card, data) => {
      if (! card.value) return false;
      data.feeling.noun = card.value.toLowerCase().trim();
    }
  },

  start2: {
    text: `Could you allow yourself to feel the {feeling.noun}?`,
    buttons: { 'OK': 'howmuch' }
  },

  done: {
    text: `Nice!`,
    buttons: { 'OK': '__exit__' }
  },

  leave: {
    text: `It's OK. Sometimes it's hard to let go and we need to stay with it for a while longer. You might feel a little better by just acknowledging the {feeling.noun}.<br>It might also help to try releasing a different feeling.`,
    buttons: { 'OK': '__exit__' }
  }
});

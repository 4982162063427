<template>
  <div>
    <f7-list v-if="categorize">
      <f7-list-group class="feeling-group" v-for="(feelings, category) in feelingCategories()" :key="category">
        <f7-list-item :title="category" group-title></f7-list-item>
        <f7-list-item
          v-for="(feeling, key) in feelings"
          :key="key"
          :title="feeling.name"
          :footer="descriptions ? feeling.description : ''"
          link="#"
          @click="gotoFeeling(key)"
          :class="highlight.includes(key) ? 'highlight' : ''"
        >
        </f7-list-item>
      </f7-list-group>
      <f7-list-group v-if="showOther">
        <f7-list-item title="More" group-title></f7-list-item>
          <f7-list-item title="Something else" footer="another feeling not listed here" link="#" @click="gotoFeeling('other')"></f7-list-item>
          <slot></slot>
      </f7-list-group>
    </f7-list>

    <f7-list v-if="! categorize">
      <f7-list-item v-if="title" :title="title" group-title></f7-list-item>
      <f7-list-item
        v-for="(feeling, key) in filteredFeelings()"
        :key="key"
        :title="feeling.name"
        :footer="descriptions ? feeling.description : ''"
        link="#"
        @click="gotoFeeling(key)"
        :class="highlight.includes(key) ? 'highlight' : ''"
      >
      </f7-list-item>
      <slot></slot>
      <f7-list-item v-if="showOther" title="Something else" footer="another feeling not listed here" link="/feelings/other"></f7-list-item>
    </f7-list>
  </div>
</template>

<script>
import Utils from '../js/utils.js';
import Feelings from '../js/feelings.js';

export default {
  props: {
    // title to show (only works if categorize=false)
    title: { type: String, default: null },
    // group feelings by categories?
    categorize: { type: Boolean, default: false },
    // show descriptions?
    descriptions: { type: Boolean, default: true },
    // list of categories to exclude
    excludeCategories: { type: Array, default: () => [] },
    // list of feelings to show (key names)
    feelings: { type: Array, default: null },
    // show "Something else" typed in feeling option?
    showOther: { type: Boolean, default: false },
    // custom exit url when going into a feeling
    exiturl: { type: String, default: null },
    // function to call before going to feeling
    beforeLink: { type: Function, default: (feeling) => {} },
    // function to call before going to feeling
    highlight: { type: Array, default: () => [] },
  },
  methods: {
    gotoFeeling(key) {
      this.beforeLink(key);
      this.$f7router.navigate(`/feelings/${key}`, { props: { exiturl: this.exiturl } });
    },
    feelingCategories() {
      var categories = Utils.groupByObjects(this.filteredFeelings(), 'category');
      for (var name of this.excludeCategories) {
        delete categories[name];
      }
      return categories;
    },
    filteredFeelings() {
      if (! this.feelings) return Feelings;
      return Utils.filterObject(Feelings, (v, k) => this.feelings.includes(k));
    },
  },
}
</script>
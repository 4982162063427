import Vue from 'vue';
import { Dom7 } from 'framework7/framework7.esm.bundle.js';
import NoSleep from 'nosleep.js';
import Narration from './narration.js';

const $$ = Dom7;

const Music = Vue.observable({
  active: false,
  music: null,
  nosleep: null,
  init() {
    const music = $$('<audio>').append(
      $$('<source>')
        .attr('src', 'media/sunny-mornings-peder-helland-128.mp3')
        .attr('type', 'audio/mpeg')
    ).attr('loop', true);
    $$('document').append(music);
    this.music = music[0];
    this.music.volume = 0.2;
    this.nosleep = new NoSleep();
  },

  toggle() {
    this.active ? this.pause() : this.play();
  },

  play() {
    this.music.play().then(this.setupMediaSession());
    this.active = true;
    this.nosleep.enable();
    Narration.resume();
  },

  pause() {
    this.music.pause();
    this.active = false;
    this.nosleep.disable();
    Narration.pause();
  },

  setupMediaSession() {
    // set up Android player controls
    if ('mediaSession' in navigator) {
      navigator.mediaSession.setActionHandler('play', () => this.play() );
      navigator.mediaSession.setActionHandler('pause', () => this.pause() );
    }
  }
});

export default Music;

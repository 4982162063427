<template>
  <div class="celebrate">
    <f7-icon ref="icons" v-for="n in count" :key="n" :f7="icon" color="theme"></f7-icon>
  </div>
</template>

<style>
@keyframes float1 {
  0% { opacity: 0; }
  10% { opacity: 1; }
  100% { transform: scale(2.0); opacity: 0; top: -100px; left: -50px; }
}

@keyframes float2 {
  0% { opacity: 0; }
  10% { opacity: 1; }
  100% { transform: scale(3.0); opacity: 0; top: -100px; left: 0px; }
}

@keyframes float3 {
  0% { opacity: 0; }
  10% { opacity: 1; }
  100% { transform: scale(2.0); opacity: 0; top: -100px; left: +50px; }
}

.animate1 {
  animation: float1 2s ease-out forwards;
}

.animate2 {
  animation: float2 2s ease-out forwards;
}

.animate3 {
  animation: float3 2s ease-out forwards;
}

.celebrate {
  position: relative;
}

.celebrate .icon {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

</style>

<script>
  export default {
    props: {
      count: {
        type: Number,
        default: 9,
      },
      icon: {
        type: String,
        default: 'sparkles',
      },
      autostart: {
        type: Number,
        default: 1000,
      }
    },
    methods: {
      animate() {
        var timeout = 0;
        var count = 0;
        this.$refs.icons.forEach((icon) => {
          setTimeout(() => {
            this.$$(icon.$el).addClass('animate' + (count % 3 + 1));
            count++;
          }, timeout)
          timeout += 100;
        });
        this.$refs.icons.forEach((icon) => {
          setTimeout(() => {
            for (var i = 1; i <= 3; i++) {
              this.$$(icon.$el).removeClass('animate' + i);
            }
          }, timeout + 2000);
        });
      }
    },
    mounted() {
      if (this.autostart > 0) {
        setTimeout(() => {
          this.animate();
        }, this.autostart);
      }
    }
  }
</script>
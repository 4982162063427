var Lessons = {
  introduction: {
    title: 'Introduction',
    subtitle: 'Basic stress relief',
    text: {
      main: `
        Hey there. My name is Eli Finer.

        Welcome to Wuju.

        These are difficult times in India. There is fear, uncertainty and doubt everywhere. And yet there is light as well.

        Any difficulty we face can be made worse or better, depending on the quality of our thoughts. Negative, self-defeating thoughts make every challenge seem impossible. They drain our energy and limit our options. Clear thinking can help us see new ways to improve our situation. It can help us find compassion for ourselves and for others and even improve the way our bodies deal with disease.

        This app will help you investigate your thoughts  and help you let go of ones that don't serve you anymore. It will then help you find gratitude and courage to face your circumstances.

        This only takes a few minutes. But you may find you need to do this every day or two as changing circumstances require even higher levels of equanimity.

        Let's begin.
      `,
      secondary: `
        This is the main screen of the app where you will usually start your exploration. You can see a list of common thoughts here that might be going through your mind.

        When you find something that matches your state of mind, just click it and follow the prompts. And if you can't find any thought that works, take a look at the list of feelings by clicking the Feelings tab, and see if there's anything there that works.

        And if none of those seems to be helpful, please reach out to me by clicking "Contact me" in the menu.

        I'll do my best to help.
      `,
      summary: `
        Before you go, take a couple of minutes to reflect on how you're feeling now.

        It's a good idea to spend a few minutes every night with Wuju just before bed. It should help clear your mind and improve your sleep.

        And one last thing. Please share Wuju with your friends and family. Lots of people need help these days, but very few talk about it.

        Good luck.
    `,
    },
    narration: {
      main: 'lessons-introduction-india-main-2021-04-26.mp3',
      secondary: 'lessons-introduction-india-secondary-2021-04-25.mp3',
      summary: 'lessons-introduction-india-summary-2021-04-25.mp3',
    },
    // question: `What's on your mind?`,
    // feelings: ['tension', 'fear', 'anger', 'shame'],
  },
};

Object.keys(Lessons).forEach(key => {
  Lessons[key].key = key;
});

export default Lessons;

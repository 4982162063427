var raw = `
The river that flows in you also flows in me.
- Kabir

Your own Self-Realization is the greatest service you can render the world.
- Ramana Maharishi

There is neither Past nor Future. There is only the Present.
- Ramana Maharshi

Let what comes come. Let what goes go. Find out what remains.
- Ramana Maharshi

If you cry because the sun has gone out of your life, your tears will prevent you from seeing the stars.
- Rabindranath Tagore

You can’t cross the sea merely by standing and staring at the water.
- Rabindranath Tagore

It is very simple to be happy, but it is very difficult to be simple.
- Rabindranath Tagore

Faith is the bird that feels the light and sings when the dawn is still dark.
- Rabindranath Tagore

Let your life lightly dance on the edges of time like dew on the tip of a leaf.
- Rabindranath Tagore

Cultivation of mind should be the ultimate aim of human existence.
- Dr. Ambedkar

A jug fills drop by drop.
- Gautama Buddha

Drop by drop is the water pot filled. Likewise, the wise man, gathering it little by little, fills himself with good.
- Gautama Buddha

Hatred ceases through love.
- Gautama Buddha

He who loves 50 people has 50 woes; he who loves no one has no woes.
- Gautama Buddha

Holding on to anger is like grasping a hot coal with the intent of throwing it at someone else; you are the one who gets burned.
- Buddhaghosa

Yesterday I was clever, so I wanted to change the world. Today I am wise, so I am changing myself.
- Rumi

The wound is the place where the light enters you.
- Rumi

Your heart is the size of an ocean. Go find yourself in its hidden depths.
- Rumi

Love is the bridge between you and everything.
- Rumi

Sell your cleverness and buy bewilderment. Cleverness is mere opinion. Bewilderment brings intuitive knowledge.
- Rumi

As you start to walk on the way, the way appears.
- Rumi

Wherever you are, and whatever you do, be in love.
- Rumi

It is compassion, the most gracious of virtues, which moves the world.
- Thiruvalluvar

The folly of follies is watching another's joy or gain. And, comparing it with one’s own, feel self-pitying pain.
- Thiruvalluvar

If you don't like something, change it. If you can't change it, change your attitude.
- Maya Angelou

People will forget what you said, people will forget what you did, but people will never forget how you made them feel.
- Maya Angelou

I know why the caged bird sings.
- Maya Angelou

The flame that burns twice as bright burns half as long.
- Lao Tzu

The wise man is one who, knows, what he does not know.
- Lao Tzu

Let it be still, and it will gradually become clear.
- Lao Tzu

The heart that gives, gathers.
- Lao Tzu

One is never afraid of the unknown; one is afraid of the known coming to an end.
- Jiddu Krishnamurti

You don't fail until you stop trying.
- Albert Einstein

Only those who dare to fail greatly can ever achieve greatly.
- John F. Kennedy

I avoid looking forward or backward, and try to keep looking upward.
- Charlotte Bronte

The only journey is the one within.
- Rainer Maria Rilke

The best way out is always through.
- Robert Frost

Fall seven times, stand up eight.
- Japanese Proverb

Whether you think you can or you can’t, you’re right.
- Henry Ford
`;

// parse quotes from raw text
var quotes = raw.split('\n\n').map(block => {
   var matches = (block + '').match(/(.+)^-(.+)/ms);
   return {
      text: matches[1].trim(),
      author: matches[2].trim()
   }
});

export default quotes;
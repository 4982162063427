import navLeft from 'framework7-vue/components/nav-left';
import Vue from 'vue';
import Settings from './settings.js';

var Progress = Vue.observable({
  total: 14, // total runs for 100% progress
  current: typeof(Settings.progress.get()) == 'number' ? Settings.progress.get() : 0,

  remaining() {
    return this.total - this.current;
  },

  progress() {
    return Math.round(this.current / this.total * 100);
  },

  reset() {
    this.current = 0;
    Progress.update();
  },

  advance() {
    this.current = Math.min(this.current + 1, this.total + 1);
    Progress.update();
  },

  complete() {
    this.current = this.total + 1;
    Progress.update();
  },

  update() {
    Settings.progress.set(this.current);
  },

  message() {
    if (this.remaining() == 0) {
      return `you're a pro now!`;
    } else if (this.remaining() == 1) {
      return `only one practice run to go!`;
    } else if (this.remaining() == 2) {
      return `just two practice runs to go`;
    } else if (this.progress() == 50) {
      return `half way there, ${this.remaining()} practice runs to go`;
    } else {
      return `${this.remaining()} practice runs to go`;
    }
  }
});

export default Progress;
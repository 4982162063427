<template>
  <div :class="'progress'+(narrow ? ' narrow' : '')">
    <div class="progress-inner" :style="`width: ${progress}%`"></div>
    <div class="progress-label">{{label}}</div>
  </div>
</template>

<style scoped>
  .progress {
    background: #666;
    height: 2em;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
  }
  .progress-inner {
    background: var(--f7-theme-color);
    filter: saturate(0.2) brightness(1.5);
    height: 100%;
  }
  .progress-label {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0.2em;
    right: 0;
    color: white;
  }
  .narrow {
    height: 3px
  }
</style>

<script>
  export default {
    props: {
      progress: Number,
      label: String,
      narrow: {
        type: Boolean,
        default: false,
      }
    },
  }
  </script>
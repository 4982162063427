<template>
  <f7-page name="test">
    <f7-block>
      <div class="outer">
        <div ref="inner" class="inner breathe">
        </div>
      </div>
      <f7-button @click="animate()">Animate</f7-button>
    </f7-block>
  </f7-page>
</template>

<style scoped>
  .outer {
    width: 100%;
    height: 10px;
    /* border: 1px solid white; */
  }
  .inner {
    width: 0%;
    height: 100%;
    background: orange;
    margin: auto;
    border-radius: 20px;
  }

  @keyframes breathe {
    0% { width: 0%; opacity: 0}
    25% { width: 0%; opacity: 0}
    75% { width: 50%; opacity: 1}
    100% { width: 50%; opacity: 1}
  }

  .breathe {
    animation: breathe 6s ease-out alternate infinite;
  }

</style>>

<script>
  export default {
    methods: {
      // animate() {
      //   this.$$(this.$refs.inner).addClass('breathe-in');
      //   setTimeout(() => {
      //     this.$$(this.$refs.inner).addClass('breathe-out');
      //   }, 6000);
      //   setTimeout(() => {
      //     this.$$(this.$refs.inner).removeClass('breathe-in').removeClass('breathe-out');
      //   }, 6000*2);
      // }
    }
  }
</script>
<template>
<f7-app :params="f7params" color-theme="teal">
  <f7-panel right cover theme-dark>
    <f7-page>
      <f7-navbar title="Menu"></f7-navbar>
      <f7-list>
          <f7-list-item link="/home" title="Home" panel-close></f7-list-item>
          <f7-list-item link="/insights" title="Insights" panel-close></f7-list-item>
          <f7-list-item link="/history" title="History" panel-close></f7-list-item>
          <f7-list-item link="/lessons" title="Guidance" panel-close></f7-list-item>
      </f7-list>
      <f7-list>
          <f7-list-item class="desktop">
            <span>Full screen</span>
            <f7-toggle @change="toggleFullScreen()"></f7-toggle>
          </f7-list-item>
      </f7-list>
      <f7-list>
          <f7-list-item link="/settings" title="Settings" panel-close></f7-list-item>
          <f7-list-item link="#" @click="contact()" title="Contact me" panel-close></f7-list-item>
          <f7-list-item link="/about" title="About" panel-close></f7-list-item>
      </f7-list>
    </f7-page>
  </f7-panel>
  <f7-view main class="safe-areas" url="/" :push-state="true"></f7-view>
</f7-app>
</template>


<script>
  import Routes from '../js/routes.js';
  import Email from '../js/email.js';
  import Record from '../js/record.js';
  import Narration from '../js/narration.js';

  export default {
    data() {
      return {
        f7params: {
          name: 'Wuju',
          theme: 'auto',
          routes: Routes,
          swipe: 'both',
          swipeOnlyClose: true,
          dialog: {
            keyboardActions: true,
            autoFocus: true,
          }
        },
      };
    },
    methods: {
      toggleFullScreen() {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        } else {
          document.documentElement.requestFullscreen();
        }
      },
      contact() {
        Email.openContactDialog(this.$f7);
      },
      record() {
        Record.record(this.$f7);
      },
      onRouteChange() {
        if (Narration.playing()) {
          Narration.stop().ting();
        }
      }
    },
    mounted() {
      this.$f7ready(() => {
        this.$f7.on('routeChange', this.onRouteChange);
        // don't display install prompt (handled manually in /home)
        window.addEventListener('beforeinstallprompt', (event) => {
          event.preventDefault();
        });
      });
    },
  }
</script>

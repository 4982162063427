import Feelings from './feelings.js';
import Flows from './flows.js';

function validateFeelings() {
  Object.keys(Feelings).forEach((key) => {
    const feeling = Feelings[key];
    (feeling.next || []).forEach((f) => {
      if (! Feelings[f]) {
        throw new Error(`Feelings: ${key}.next refers to non-existing feeling ${f}`);
      }
    });
    (feeling.stuck || []).forEach((f) => {
      if (! Feelings[f]) {
        throw new Error(`Feelings: ${key}.stuck refers to non-existing feeling ${f}`);
      }
    });
  });
}

function validateFlows() {
  Object.keys(Flows).forEach((flowKey) => {
    const flow = Flows[flowKey];
    Object.keys(flow).forEach((cardKey) => {
      const card = flow[cardKey];
      Object.keys(card.buttons).forEach((text) => {
        const target = card.buttons[text];
        if (! flow[target] && ! target.match(/__.+__/) && target !== '') {
          throw new Error(`Flows: ${cardKey}.buttons.${text} refers to non-existing card '${target}' in flow '${flowKey}'`);
        }
      });
      if (card.timeout && ! flow[card.timeout.action]) {
        throw new Error(`Flows: ${cardKey}.timeout.action refers to non-existing card '${card.timeout.action}' in flow '${flowKey}'`);
      }
    });
  })
}

function validate() {
  validateFeelings();
  validateFlows();
}

export default {
  validate
}

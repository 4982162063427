// Import regenerator-runtime (for async in older browsers via babel)
import regeneratorRuntime from "regenerator-runtime";

// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.less';

// Import App Component
import App from './app.vue';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init Social Sharing
import VueSocialSharing from 'vue-social-sharing';
Vue.use(VueSocialSharing);

// Init Clipboard
import VueClipboard from 'v-clipboard';
Vue.use(VueClipboard)

// Init Email
import Email from '../js/email.js';
Email.init();

// Init Service Worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/service-worker.js');
  });
}

// Init Audio
import Music from './music.js';
Music.init();
import Narration from './narration.js';
Narration.init();

// Global Components
import WujuNavbar from '../components/wuju-navbar.vue';
Vue.component('wuju-navbar', WujuNavbar);
import WujuCelebrate from '../components/wuju-celebrate.vue';
Vue.component('wuju-celebrate', WujuCelebrate);
import WujuProgress from '../components/wuju-progress.vue';
Vue.component('wuju-progress', WujuProgress);
import WujuQuote from '../components/wuju-quote.vue';
Vue.component('wuju-quote', WujuQuote);
import WujuFeelingList from '../components/wuju-feeling-list.vue';
Vue.component('wuju-feeling-list', WujuFeelingList);
import WujuHelpSheet from '../components/wuju-help-sheet.vue';
Vue.component('wuju-help-sheet', WujuHelpSheet);
import WujuContact from '../components/wuju-contact.vue';
Vue.component('wuju-contact', WujuContact);
import WujuShare from '../components/wuju-share.vue';
Vue.component('wuju-share', WujuShare);
import WujuBreathe from '../components/wuju-breathe.vue';
Vue.component('wuju-breathe', WujuBreathe);

// Validate data
import Validator from './validator.js';
Validator.validate();

// Init global store
import Store from './store.js';
Vue.prototype.$store = Store;

// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),

  // Register App Component
  components: {
    app: App
  },
});
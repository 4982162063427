<template>
  <f7-page name="home">
    <wuju-navbar :menu="true" :back="true" title="History"></wuju-navbar>
    <div v-if="sortedHistory.length">
      <f7-card v-for="(item, index) in sortedHistory" :key="index">
        <f7-card-content style="padding-top :5px; padding-bottom :0; ">
          <div v-if="item.feeling === 'gratitude'">
            <p>Gratitude at {{intensity(item.intensity.end * 10)}}%</p>
            <p v-html="item.answers ? linebreaks(item.answers.grateful || '', 'normal') : ''"></p>
          </div>
          <div v-if="item.feeling !== 'gratitude'">
            <p v-if="item.reason">{{capitalize(item.feeling)}} about {{Array.isArray(item.reason) ? englishList(item.reason, 'and') : item.reason.trim().replace(/\.$/, '')}}.</p>
            <p v-if="item.location">Felt the {{item.feeling}} in {{englishList((item.location || []).map((v) => 'my ' + v), 'and')}}.</p>
            <p v-if="! item.location && ! item.reason">Released some {{item.feeling}}.</p>
            <p>Went from {{intensity(item.intensity.start)}} to {{intensity(item.intensity.end)}} in {{duration(item.elapsed)}}!</p>
          </div>
        </f7-card-content>
        <f7-card-footer>
          <p class="flex">{{moment(item.date).fromNow()}}</p>
        </f7-card-footer>
      </f7-card>
    </div>
    <f7-block v-if="! sortedHistory.length">
      <p class="center">There is no history here yet. A history of all your emotional processing will appear here.</p>
    </f7-block>
  </f7-page>
</template>

<script>
import Utils from '../js/utils.js';
import Music from '../js/music.js';
import Settings from '../js/settings.js';
import moment from 'moment';

export default {
  data: function() {
    return {
      history: Settings.history.get(),
      moment: moment,
    }
  },
  methods: {
    duration(millis) {
      var secs = Math.floor(millis / 1000);
      if (secs < 60) {
        return 'less than a minute';
      } else if (secs < 60*2) {
        return 'one minute';
      } else if (secs < 60*3) {
        return 'two minutes';
      } else {
        return Math.floor(secs / 60) + ' minutes';
      }
    },
    intensity(v) {
      if (v == 0) return 0;
      if (v < 10) return 1;
      return Math.floor(v / 10);
    },
    ...Utils,
  },
  computed: {
    sortedHistory() {
      return this.history.sort((a, b) => moment(b.date) - moment(a.date));
    }
  }
}

</script>

export default {
  start: {
    text: `Now you know what it feels like to let go of {feeling.noun}, and how quickly it happens.
    <br>
    Would you like to dig a little deeper and see how it impacts your life?`,
    buttons: { 'Yes': 'howlong', 'No': '__exit__' },
  },
  howlong: {
    text: `How long have you been carrying the {feeling.noun} around?`,
    control: {
      type: 'pills',
      items: ['hours', 'days', 'weeks', 'months', 'years'],
      other: false,
    },
    buttons: { 'OK': 'howoften' },
  },
  howoften: {
    text: `How often does it come up?`,
    control: {
      type: 'pills',
      items: ['every day', 'a few times a week', 'a few times a month', 'once in while'],
      other: false,
    },
    buttons: { 'OK': 'areas' },
  },
  areas: {
    text: `What areas of your life does the {feeling.noun} impact?`,
    control: {
      type: 'pills',
      items: ['relationships', 'career', 'finances', 'health', 'fitness', 'sleep', 'eating habits', ' drinking habits'],
      other: false,
      multiselect: true,
    },
    buttons: { 'OK': 'summary1' },
  },
  summary1: {
    text: `So the {feeling.noun} has been around for {answers.howlong}. It comes up {answers.howoften} and you're struggling with your {and(answers.areas)} because of it.
    <br>
    What would your {answers.areas[0]} be like <i>without</i> the {feeling.noun}?
    <br>
    What would you be able to do? Who could you become?
    <br>
    Just close your eyes and think about that for a minute.
    `,
    buttons: { 'OK': 'summary2' },
  },
  summary2: {
    text: `
    Quite the vision, isn't it?
    `,
    buttons: { 'Yeah': 'summary3' },
  },
  summary3: {
    text: `
    But {feeling.noun} is sneaky. It's not always easy to notice it in the moment. It's even harder to remember that it's just a feeling, a feeling you can let go of regardless of circumstances.
    <br>
    One useful trick is to learn to notice the recurring thoughts that arise with it.
    `,
    buttons: { 'Next': 'thoughts' },
  },
  thoughts: {
    text: `So what thoughts do you tend to have when you're feeling {feeling.noun}?
    <br>
    Here are some examples to start from. You can enter as many as you like, one on each line.`,
    help: `These thoughts will show up on your home page when you open Wuju. You can edit them any time.`,
    control: {
      type: 'textarea',
      default: `{newline(feeling.thoughts)}`,
    },
    buttons: { 'OK': 'ready' },
  },
  ready: {
    text: `
    Wonderful!
    <br>
    Next time you think {or(quote(filter(split(answers.thoughts))))}, you'll be able to remember that it's just a bit of {feeling.noun} hijacking your mind.
    <br>
    {capitalize(feeling.noun)} will never get you off track again.
    `,
    buttons: { 'OK': '__signup__' },
  }
}

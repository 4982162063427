var Guidance = {
  intro: {
    text: `
      Hey there, my name is Eli Finer.

      Welcome to Wuju.

      You might be here because you're struggling with procrastination. Maybe you're constantly feeling stressed or overwhelmed. Maybe you've been diagnosed with anxiety or depression or even PTSD. And maybe you're just curious.

      At their core almost all internal struggles are the result of emotions we haven't fully acknowledged or haven't fully felt. This app will help you tune into those emotions and actively release them. Releasing emotions is a surprisingly simple and effective process. And I'm going to guide you through it.

      Before we begin, I want to ask you to please just close your eyes for a few moments and connect to whatever brought you here. It might be some tension or self judgment or a sense of numbness. It might be thoughts running through your head or maybe a general sense of confusion. Whatever it is, just listen to it for a few moments and let's just take a breath together.

      (narration continues...)
    `,
    /*
      Wonderful. You can now very gently open your eyes.

      

      The first thing we're going to do, is just scroll down and take a look at the list of emotions here. We have some surface feelings, some deeper core emotions, some mindsets and we also have the positive emotions that we'll get to later.

      So, just look through the list and see what resonates with your current state. If you don't know what to choose, Resistance or Tension are usually good places to start.

      And so is Doubt, especially if you're not sure this app can work for you. And if you're feeling multiple things at the same time, just choose one at random. We can get to the others later.
    */
    narration: 'topics-introduction.mp3',
  },
};

export default Guidance;
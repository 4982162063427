<template>
    <div class="breathe">
      <div ref="inner" class="inner breathe">
      </div>
    </div>
</template>

<style scoped>
  .breathe {
    margin-top: 10px;
    height: 2px;
  }

  .inner {
    width: 0%;
    height: 100%;
    background: var(--f7-theme-color);
    margin: auto;
    border-radius: 20px;
    animation: breathe 8s ease-out alternate infinite;
  }

  @keyframes breathe {
    0% { width: 0%; opacity: 0}
    20% { width: 0%; opacity: 0}
    90% { width: 100%; opacity: 1}
    100% { width: 100%; opacity: 1}
  }
</style>

<script>
  export default {
  }
</script>
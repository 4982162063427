import Vue from 'vue';
import Store from '../js/store.js';

export default {
  start: {
    text: `Could you allow yourself to feel the {feeling.noun}?`,
    buttons: { 'Yes': 'extra', 'No': 'cantfeel' },
    narration: 'faq-release-introduction.mp3',
    faq: [
      {
        title: `What do you mean <em>allow myself to feel</em>?`,
        text: `
          We've been conditioned since childhood to not allow ourselves feel our emotions. We've been told repeatedly to not be afraid, to not be angry, to not be sad. The people who said it meant we shouldn't act these emotions out, especially in public, and they were right. But our young minds were quite literal and created a barrier between us and these feelings.

          Agreeing to feel the emotions removes that barrier and opens up the ability to heal and move past it.
        `,
        narration: 'faq-i-dont-know-how-to-feel.mp3',
      },
    ],
    // timeout: {
    //   delay: 10*1000,
    //   action: 'distraction',
    // }
  },

  cantfeel: {
    text: `It's OK. Sometimes it's hard to allow ourselves to feel. You might be feeling some {or(feeling.stuck)} that you need to work through first.`,
    control: {
      type: 'list',
      items: 'feeling.stuck',
    },
    buttons: { 'Other feelings': '__feelings__', 'Maybe Later': '__exit__' },
  },

  extra: {
    text: `{feeling.extra.title}`,
    control: {
      // type: 'textarea',
      type: 'pills',
      items: 'feeling.extra.options',
      multiselect: true,
    },
    buttons: { 'OK': 'location', 'Skip': 'location' },
    before: (card, data) => {
      // skip this card to if there are no extra title for this feeling
      return 'location';
      // if (! data.feeling.extra.title) return 'location';
    },
    after: (card, data) => {
      // update the extra value if one was selected
      if (! card.value) return true;
      data.feeling.extra.value = card.value;
    }
  },

  location: {
    text: `Where in your body do you feel the {feeling.noun}?`,
    control: {
      type: 'pills',
      items: 'feeling.locations',
      multiselect: true,
    },
    buttons: { 'OK': 'howmuch', 'Skip': 'howmuch' },
    before: (card, data) => {
      // skip this card to if there are no body locations to this feeling
      if (! data.feeling.locations) return 'howmuch';
    },
    after: (card, data) => {
      // update the location if a location was selected
      if (! card.value) return true;
      data.feeling.location = card.value;
    }
  },

  howmuch: {
    text: `How much {feeling.noun} do you feel?`,
    buttons: { 'Next': 'stay', },
    control: { type: 'range' },
    before: (card, data) => {
      card.value = Store.intensity[data.feeling.key] || 50;
    },
    after: (card, data) => {
      data.intensity = data.initialIntensity = card.value;
      // xxx: accessing global data here is pretty awful
      Vue.set(Store.intensity, data.feeling.key, card.value);
    },
  },

  stay: {
    text: `Just stay with that feeling of {feeling.noun} for a few moments. Take a breath if you need to.`,
    control: { type: 'breathe' },
    buttons: { 'OK': 'could' },
  },

  could: {
    text: `Could you let some of the {feeling.noun} go?`,
    buttons: { 'Yes': 'would', 'Not yet': 'holdon' },
    faq: [
      {
        title: `What do you mean by "letting go"?`,
        text: `
          In order to understand what letting go of emotions means, we first need to understand how we're holding on to them. Imagine a small child, maybe a year old. She can't speak yet, and can't form complex thoughts. She cries when she's sad, angry or in pain, she laughs when she's happy and she switches easily and quickly between these emotions. That's how it works until thinking becomes part of the equation.

          Thinking allows us to attach reasons and explanations to our emotions, often in the form of blame we direct at ourselves and our circumstances. These thoughts in turn reinforce the emotions, that would have quickly passed without them. And so we find ourselves stuck in this thinking-feeling loop, often thinking we shouldn't even be feeling this way.

          Letting go of emotions simply means no longer holding on to them and allowing them to dissipate. It's as natural as breathing out after breathing in or unclenching a clenched fist. It happens spontaneously when you honestly agree to say Yes to the title "Could you let it go?"
        `,
        narration: 'faq-what-do-you-mean-by-letting-go.mp3'
      },
      {
        title: `I don't know how to let go`,
        text: `
          This question "Could you let it go?" is actually simpler than it looks. If you don't know how to let go, it simply means your text to it is "No". Don't worry, like everywhere else in this app, "No" is a perfectly valid text that just means you need to spend some more time with this emotion. The next few steps should help with that.
        `,
        narration: 'faq-i-dont-know-how-to-let-go.mp3'
      },
    ]
  },

  would: {
    text: `Would you let it go?`,
    buttons: { 'Yes': 'when', 'Not yet': 'holdon' },
  },

  when: {
    text: `When?`,
    buttons: { 'Now': 'magic', 'Not yet': 'holdon' },
  },

  holdon: {
    text: `Could you allow yourself to hold on to the {feeling.noun} for a bit? It may help to squeeze your fist to emphasize this.`,
    buttons: { 'OK': 'holdon2' }
  },

  holdon2: {
    text: `It's OK to hold on. Just take a breath and feel the {feeling.noun} as fully as you can.`,
    control: { type: 'breathe' },
    buttons: { 'OK': 'couldnow' }
  },

  magic: {
    text: `Now just pause for a moment and take a deep breath. Let the magic work.`,
    control: { type: 'breathe' },
    buttons: { 'OK': 'howmuchmore' },
  },

  howmuchmore: {
    text: `How much {feeling.noun} do you feel now?`,
    buttons: { 'Next': 'staymore', 'It\'s gone': 'done' },
    control: { type: 'range' },
    faq: [
      {
        title: `Can {feeling.noun} dissapear completely?`,
        text: `Yes, it can - at least for a while. We're not supposed to get rid of our emotions, they are part of what makes us human. But we can definitely stop holding on to them and let them pass through us gently and peacefully.
        You might encounter a sense of doubt or resistance as you work with the emotions. If that happens and you want to explore a little deeper, you can switch to resistance or to doubt using the menu in the bottom right corner.
        The feeling of completely letting go is magical and is often worth the time.`,
        narration: 'faq-can-it-dissapear-completely.mp3',
      },
    ],
    before: (card, data) => {
      card.value = data.intensity;
    },
    after: (card, data) => {
      var intensity = data.intensity;
      data.intensity = card.value;
      // xxx: accessing global data here is pretty awful
      Vue.set(Store.intensity, data.feeling.key, card.value);
      if (card.value < 5) {
        return 'done';
      } else if (card.value > intensity) {
        return 'stronger';
      }
    },
  },

  stronger: {
    text: `Yes, the {feeling.noun} can get stronger as you tap into deeper layers. It's OK, just keep going.`,
    buttons: { 'Next': 'staymore' }
  },

  couldnow: {
    text: `Could you now let it go?`,
    buttons: { 'Yes': 'wouldmore', 'No': 'couldmore_tiny' }
  },

  staymore: {
    text: `Just stay with this new layer of {feeling.noun} for a bit. Take another breath if you need to.`,
    control: { type: 'breathe' },
    buttons: { 'OK': 'couldmore' },
    faq: [
      {
        title: `What do you mean by <em>layers</em>?`,
        text: `
          Our feelings often come in layers. Every layer is a little different, a little deeper then the one before it.

          Different images may arise, different thoughts and different memories. You don't have to release all of it, but you have the option to. Sometimes you may realize that the feeling has shifted and you're now feeling something else. It's OK to stop midway and go to a different feeling.
        `,
        narration: 'faq-what-do-you-mean-by-layers.mp3',
      },
    ],
  },

  couldmore: {
    text: `Could you let it go?`,
    buttons: { 'Yes': 'wouldmore', 'No': 'couldmore_tiny'  }
  },

  couldmore_tiny: {
    text: `Could you let just a tiny bit of it go?`,
    buttons: { 'Yes': 'wouldmore', 'No': 'leave' }
  },

  wouldmore: {
    text: `Would you?`,
    buttons: { 'Yes': 'whenmore', 'No': 'leave'  }
  },

  whenmore: {
    text: `When?`,
    buttons: { 'Now': 'magicmore', 'Later': 'leave'  }
  },

  magicmore: {
    text: `Now pause again and take a deep breath. Let the magic work.`,
    control: { type: 'breathe' },
    buttons: { 'OK': 'howmuchmore' },
  },

  done: {
    text: `Nice!<br>Now that you've released the {feeling.noun}, other feelings may arise.`,
    control: {
      type: 'list',
      items: 'feeling.next',
    },
    buttons: { 'Other feelings': '__feelings__', 'Nah, I\'m good': '__exit__' },
    before: (card, data) => {
      data.intensity = 0;
      // xxx: accessing global data here is pretty awful
      Vue.set(Store.intensity, data.feeling.key, card.value);
      // global.mixpanel.track('Delta', {
      //   feeling: data.feeling.noun,
      //   state: card.id,
      //   initial: data.initialIntensity,
      //   delta: data.initialIntensity,
      //   percent: 100
      // });
    }
  },

  leave: {
    text: `
    It's OK. Sometimes you encounter layers of {or(feeling.stuck)} when working with {feeling.noun}.<br>It should help releasing one of them first.`,
    control: {
      type: 'list',
      items: 'feeling.stuck',
    },
    buttons: { 'Other feelings': '__feelings__', 'Maybe Later': '__exit__' },
    before: (card, data) => {
      // global.mixpanel.track('Delta', {
      //   feeling: data.feeling.noun,
      //   state: card.id,
      //   initial: data.initialIntensity,
      //   delta: data.initialIntensity - data.intensity,
      //   percent: Math.round(100 * (data.initialIntensity - data.intensity) / data.initialIntensity),
      // });
    }
  },

  distraction: {
    text: `Hey... You got distracted, didn't you?<br><It's OK, it happens to everyone.<br>This usually means some doubt or inadequacy creeped in.<br>Just go with the flow to whatever you're feeling right now.`,
    control: {
      type: 'list',
      items: ['doubt', 'anger'],
    },
    buttons: { 'Stay with {feeling.noun}': '__previous__' },
  }
}

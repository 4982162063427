<template>
  <f7-page name="privacy">
    <wuju-navbar :menu="true" :back="true" :music="false" title="Privacy Policy"></wuju-navbar>
    <f7-block>
      <h2>Privacy Policy</h2>
      <p>
        Wuju doesn't store any data. Any communication between you and me (Eli) is confidential and won't be revealed to a third party without your express permission.
      </p>
      <p>
        I'm still working on the real Privacy Policy, but this is the gist.
      </p>
      <p>
        Please drop me a note at <f7-link href="mailto:eli@wuju.app">eli@wuju.app</f7-link> if you have any questions or concerns.
      </p>
    </f7-block>
  </f7-page>
</template>

<script>
  export default {
    data: function() {
    },
    methods: {
    },
    computed: {
    },
  }
</script>
import moment from 'moment';
import Utils from './utils.js';

var Settings = {
  _get(name, def) {
    var value = JSON.parse(localStorage.getItem('wuju:' + name));
    if (value == null) {
      value = def;
    }
    return value;
  },

  _set(name, value) {
    localStorage.setItem('wuju:' + name, JSON.stringify(value));
  },

  progress: {
    get: () => Settings._get('progress', 0),
    set: (value) => Settings._set('progress', value),
  },

  email: {
    get: () => Settings._get('email', ''),
    set: (value) => Settings._set('email', value),
  },

  narration: {
    get: () => Settings._get('narration', true),
    set: (value) => Settings._set('narration', !!value),
  },

  faq: {
    get: () => Settings._get('faq', true),
    set: (value) => Settings._set('faq', !!value),
  },

  breathing: {
    get: () => Settings._get('breathing', true),
    set: (value) => Settings._set('breathing', !!value),
  },

  shown: {
    get(name) {
      return Settings._get(`shown.${name}`, false)
    },
    set(name, value) {
      value = value === undefined ? true : value;
      Settings._set(`shown.${name}`, value)
    },
  },

  helpShown: {
    _hash(text) {
      // get hash of first 10 words (allows for editing and resembles how people think about seeing a text already)
      return Utils.hash(text.split(' ').slice(0, 10).join(' '));
    },
    get(text) {
      const hash = this._hash(text);
      return Settings._get(`helpShown.${hash}`, false)
    },
    set (text) {
      const hash = this._hash(text);
      Settings._set(`helpShown.${hash}`, true)
    },
  },

  insights: {
    get() {
      return Settings._get('insights', []);
    },
    add(options) {
      var defaults = {
        date: moment(),
        text: '',
        feeling: '',
      };
      options = Object.assign({}, defaults, options);
      var insights = Settings._get('insights', []);
      insights.push(options);
      Settings._set('insights', insights);
    }
  },

  history: {
    get() {
      return Settings._get('history', []);
    },
    add(options) {
      var defaults = {
        date: moment(),
        elapsed: 0,
        reason: '',
        location: '',
        intensity: {
          start: 0,
          end: 0,
        },
      };
      options = Object.assign({}, defaults, options);
      var history = Settings._get('history', []);
      history.push(options);
      Settings._set('history', history);
      // todo: this should probably be a uuid, not an index
      return history.length - 1;
    },
    update(index, options) {
      var history = Settings._get('history', []);
      Object.assign(history[index], options);
      Settings._set('history', history);
      return index;
    }
  },

  lessons: {
    get() {
      return Settings._get(`lessons`, {})
    },
    set(name, value) {
      value = value === undefined ? true : value;
      var lessons = this.get();
      lessons[name] = value;
      Settings._set(`lessons`, lessons);
    },
    count() {
      return Object.keys(Settings._get(`lessons`, {})).filter((v) => v).length;
    }
  },

  narrations: {
    get() {
      return Settings._get(`narrations`, {})
    },
    set(name, value) {
      value = value === undefined ? true : value;
      var narrations = this.get();
      narrations[name] = value;
      Settings._set(`narrations`, narrations);
    },
  }
}

export default Settings;

<template>
  <f7-sheet class="gradient" backdrop position="bottom" :opened="false">
    <f7-page-content>
      <f7-block>
        <f7-link class="thumbnail" @click="contact()">
          <img src="static/img/eli.jpg">
          <f7-icon class="contact" f7="chat_bubble_text_fill" size="16" color="orange"></f7-icon>
        </f7-link>
        <slot></slot>
      </f7-block>
    </f7-page-content>
    <f7-toolbar position="bottom">
      <f7-link></f7-link>
      <f7-link sheet-close icon-f7="chevron_down"></f7-link>
      <f7-link></f7-link>
    </f7-toolbar>
  </f7-sheet>
</template>

<style scoped>
  .sheet-modal {
    height: 60%;
  }
  .thumbnail {
    float: right;
    position: relative;
    top: 10px;
    margin: 0 0 10px 10px;
    position: relative;
  }
  .thumbnail img {
    width: 70px;
    border-radius: 10px;
  }
  .thumbnail .contact {
    position: absolute;
    bottom: 5px;
    right: 3px;
  }
</style>

<script>
  import Email from '../js/email.js';

  export default {
    props: {
      page: {
        type: String,
        default: ''
      },
    },
    methods: {
      contact() {
        Email.openContactDialog(this.$f7);
      }
    },
  }
  </script>
<template>
  <f7-page name="lesson">
    <wuju-navbar title="Wuju" subtitle="be gentle to yourself" :menu="true" :back="false"></wuju-navbar>
    <f7-card class="theme-background">
      <f7-card-content>
        <div class="large center" v-html="lesson.title"></div>
      </f7-card-content>
    </f7-card>
    <f7-block>
      <wuju-contact></wuju-contact>
      <p class="content" v-html="linebreaks(lesson.text.main)"></p>
    </f7-block>
    <div v-if="lesson.question">
      <f7-list-item :title="lesson.question" group-title></f7-list-item>
      <textarea ref="answer" class="rows resizable tall answer" :placeholder="trimLines(lesson.placeholder)" :value="answer" @change="answer = $event.target.value"></textarea>
    </div>
    <f7-toolbar position="bottom">
      <f7-link></f7-link>
      <f7-button @click="next()">Next</f7-button>
      <f7-link></f7-link>
    </f7-toolbar>
  </f7-page>
</template>

<style scoped>
  .answer {
    min-height: 7em;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
    margin-bottom: 30px;
  }

  .content {
    min-height: 100px;
  }
</style>

<script>
import Lessons from '../js/lessons.js';
import Utils from '../js/utils.js';
import Narration from '../js/narration.js';
import Thoughts from '../js/thoughts.js';

export default {
  props: {
    delay: {
      type: Number,
      default: 1000,
    }
  },
  data: function() {
    return {
        lesson: {},
        answer: '',
        thoughts: {},
    }
  },
  methods: {
    next() {
      this.$f7router.navigate(`/home`, { props: {
        install: false,
        highlight: this.lesson.feelings,
        play: this.lesson.narration.secondary,
        exiturl: `/lessons/${this.lesson.key}/summary`,
      }});
      this.$store.lesson.key = this.lesson.key;
      this.$store.lesson.answer = this.answer;
    },
    ...Utils,
  },
  beforeMount: function() {
    this.lesson = Lessons[this.$f7route.params.lesson];
  },
  mounted() {
    Narration.interrupt()
    .delay(this.delay)
    .play(this.lesson.narration.main);
  }
}
</script>
var Thoughts = {
  stress: {
    title: 'Stress and anxiety',
    thoughts: [
      `Why is this happening?`,
      `When will this end?`,
      `I don't know if I'm safe`,
      `I don't want to die`,
      `I'm afraid to run out of money`,
    ],
    feelings: ['tension', 'resistance', 'fear', 'anger'],
  },

  distractions: {
    title: 'Looking for distractions',
    thoughts: [
      `I need to smoke`,
      `I need to drink`,
      `I need to check the news`
    ],
    feelings: ['tension', 'resistance', 'craving'],
  },

  dejection: {
    title: 'Dejection',
    thoughts: [
      `Why me?`,
      `I don't know what to do`,
      `I'm not going to make it`,
      `I can't do this anymore`,
      `I have no one to turn to`,
      `I feel helpless`,
    ],
    feelings: ['apathy', 'resistance', 'fear'],
  },

  judgement: {
    title: 'Self judgement',
    thoughts: [
      `It's all my fault`,
      `I should have prepared`,
      `How did not see this coming?`,
    ],
    feelings: ['anger', 'shame', 'inadequacy'],
  },

  guilt: {
    title: 'Gratitude and guilt',
    thoughts: [
      `I feel bad for other people`,
      `I'm grateful for what I have`,
      `I feel guilty that I'm OK`,
    ],
    feelings: ['guilt', 'shame', 'gratitude'],
  },

  loss: {
    title: 'Loss',
    thoughts: [
      `This is too hard to bear`,
      `I don't know how to go on`,
      `This shouldn't have happened`,
    ],
    feelings: ['apathy', 'fear', 'anger', 'sadness'],
  }
};

export default Thoughts;

<template>
  <f7-page name="terms">
    <wuju-navbar :menu="true" :back="true" :music="false" title="Terms and Conditions"></wuju-navbar>
    <f7-block>
      <h2>Terms and Conditions</h2>
      <p>
        I'm still working on the real Terms and Conditions, but since you're here, why don't you send me your mailing address to <f7-link href="mailto:eli@wuju.app">eli@wuju.app</f7-link>?
        <br>I'll send you a hand written postcard congratulating your on your attention to detail. Some day this note will be hidden deeply inside the real document.
      </p>
    </f7-block>
  </f7-page>
</template>

<script>
  export default {
    data: function() {
    },
    methods: {
    },
    computed: {
    },
  }
</script>